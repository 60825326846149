<template>
  <v-card :loading="loading"
    ><v-img
      height="100%"
      width="100%"
      min-height="10rem"
      :src="
        post.category === '크레파스 워십'
          ? `https://drive.google.com/uc?export=view&id=${post.imageUrl}`
          : `https://img.youtube.com/vi/${post.youtubeUrl}/0.jpg`
      "
      @click.stop="clickPost()"
    ></v-img>
  </v-card>
</template>

<script>
import PostDetail from './PostDetail.vue';
import router from '../../router/router.js';
import axios from 'axios';

export default {
  name: 'PostThumbnail',
  components: { PostDetail },
  props: ['post'],
  data: () => ({
    loading: false,
    selection: 1,
    dialog: false,
  }),
  created() {},
  methods: {
    clickPost() {
      router.push({
        path: `/project/videos/${this.post._id}`,
      });
      axios.post(`${this.$ROOT_API}/posts/${this.post._id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
