<template>
  <v-row class="pt-10 mt-1 mx-5 mb-10">
    <v-col
      v-for="post in allPosts"
      :key="post.id"
      cols="12"
      sm="4"
      md="3"
      lg="3"
      xl="3"
    >
      <PostThumbnail class="thumbnail" :post="post" />
    </v-col>
  </v-row>
</template>

<script>
import PostThumbnail from './PostThumbnail.vue';
import PostDetail from './PostDetail.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'VideoContent',
  data() {
    return {
      posts: '',
    };
  },
  components: { PostThumbnail, PostDetail },
  computed: mapGetters(['allPosts']),

  methods: {
    ...mapActions(['getPosts', 'deleteVideo']),
  },
  created() {
    this.getPosts('비디오 컨텐츠');
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  cursor: pointer;
}
</style>
